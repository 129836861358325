













import { Component, Vue, Watch } from 'vue-property-decorator';
import { shipArrivalStoreGetter } from '@/store/shipArrival.store';
import ShipArrival from '@/models/ShipArrival.model';
import { namespace } from 'vuex-class';
import { SlotType } from '@/enums/SlotType.enum';

const ShipArrivalStore = namespace('shipArrival');

@Component({
    components: {
        CarSlotAmountIndicatorComponent: () => import(
            /* webpackChunkName: "CarSlotAmountIndicatorComponent" */
            '@/components/calendar/misc/CarSlotAmountIndicator.component.vue'),
        HHSlotAmountIndicatorComponent: () => import(
            /* webpackChunkName: "HHSlotAmountIndicatorComponent" */
            '@/components/calendar/misc/HHSlotAmountIndicator.component.vue'),
    }
})
export default class BookingOverviewComponent extends Vue {

    @ShipArrivalStore.Getter(shipArrivalStoreGetter.CALENDAR_SHIP_ARRIVAL)
    private _calendarShipArrival!: ShipArrival | null;

    public get calendarShipArrival(): ShipArrival | null {
        return this._calendarShipArrival;
    }

    public bookingOverview: { cars: number, heavy: number } | null = null;

    @Watch('calendarShipArrival', { immediate: true })
    private onCalendarShipArrivalChange() {
        this.bookingOverview = {
            cars: 0,
            heavy: 0
        };
        if (this.calendarShipArrival?.booking) {
            this.calendarShipArrival.booking.bookedSlots.forEach(bookedSlot => {
                if (bookedSlot.slotType === SlotType.CAR) {
                    this.bookingOverview!.cars += bookedSlot.amount!;
                } else {
                    this.bookingOverview!.heavy += bookedSlot.amount!;
                }
            })
        }
    }

    public get requiredCarSlots(): number {
        const carCount = this.calendarShipArrival!.carsImportVolume! + this.calendarShipArrival!.carsExportVolume!;
        return Math.ceil(carCount / process.env.VUE_APP_CAR_SLOT_VOLUME);
    }

    public get requiredHeavySlots(): number {
        const heavyCount = this.calendarShipArrival!.heavyImportVolume! + this.calendarShipArrival!.heavyExportVolume!;
        return Math.ceil(heavyCount / process.env.VUE_APP_HEAVY_SLOT_VOLUME);
    }
}
